<template>
  <div>
    <div class="modal fade" id="modal_solicitud_excel">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Informe Tif Solicitudes Excel
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-InfoTurno"
                  data-toggle="tab"
                  href="#check_filtros"
                  >listado</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-InfoTurno"
                  data-toggle="tab"
                  href="#input_filtros"
                  >filtros</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="check_filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="check_fecha_ini"
                                v-model="check.fecha_ini"
                              />
                              <label
                                class="custom-control-label"
                                for="check_fecha_ini"
                                :class="check.fecha_ini ? 'text-primary' : ''"
                                >Fecha Inicio</label
                              >
                            </div>
                          </div>
                          <div>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="check_fecha_fin"
                                v-model="check.fecha_fin"
                              />
                              <label
                                class="custom-control-label"
                                for="check_fecha_fin"
                                :class="check.fecha_fin ? 'text-primary' : ''"
                                >Fecha Final</label
                              >
                            </div>
                          </div>
                          <div>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="check_empresa"
                                v-model="check.empresa"
                              />
                              <label
                                class="custom-control-label"
                                for="check_empresa"
                                :class="check.empresa ? 'text-primary' : ''"
                                >Empresa</label
                              >
                            </div>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_turno"
                              v-model="check.turno"
                            />
                            <label
                              class="custom-control-label"
                              for="check_turno"
                              :class="check.turno ? 'text-primary' : ''"
                              >Turno</label
                            >
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_producto"
                              v-model="check.producto"
                            />
                            <label
                              class="custom-control-label"
                              for="check_producto"
                              :class="check.producto ? 'text-primary' : ''"
                              >Producto</label
                            >
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_ruta"
                              v-model="check.ruta"
                            />
                            <label
                              class="custom-control-label"
                              for="check_ruta"
                              :class="check.ruta ? 'text-primary' : ''"
                              >Ruta</label
                            >
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_tipo_tarifa"
                              v-model="check.tipo_tarifa"
                            />
                            <label
                              class="custom-control-label"
                              for="check_tipo_tarifa"
                              :class="check.tipo_tarifa ? 'text-primary' : ''"
                              >Tipo Tarifa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_tipo_vehiculo"
                              v-model="check.tipo_vh"
                            />
                            <label
                              class="custom-control-label"
                              for="check_tipo_vehiculo"
                              :class="check.tipo_vh ? 'text-primary' : ''"
                              >Tipo Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_bloque"
                              v-model="check.bloque"
                            />
                            <label
                              class="custom-control-label"
                              :class="check.bloque ? 'text-primary' : ''"
                              for="check_bloque"
                              >Bloque</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_operacion"
                              v-model="check.operacion"
                            />
                            <label
                              class="custom-control-label"
                              :class="check.operacion ? 'text-primary' : ''"
                              for="check_operacion"
                              >Operación</label
                            >
                          </div>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="check_justificacion"
                            v-model="check.justificacion"
                          />
                          <label
                            class="custom-control-label"
                            for="check_justificacion"
                            :class="check.justificacion ? 'text-primary' : ''"
                            >Justificación</label
                          >
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_observaciones"
                              v-model="check.observaciones"
                            />
                            <label
                              class="custom-control-label"
                              for="check_observaciones"
                              :class="check.observaciones ? 'text-primary' : ''"
                              >Observaciones</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_estado"
                              v-model="check.estado"
                            />
                            <label
                              class="custom-control-label"
                              for="check_estado"
                              :class="check.estado ? 'text-primary' : ''"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="input_filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>fecha Inicial</label>
                      <input
                        v-model="form.fecha_inicial"
                        type="date"
                        class="form-control form-control-sm shadow-sm"
                        @input="validarFechas()"
                        :class="
                          $v.form.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label>fecha Final</label>
                      <input
                        v-model="form.fecha_final"
                        type="date"
                        class="form-control form-control-sm shadow-sm"
                        @input="validarFechas()"
                        :class="
                          $v.form.fecha_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <!-- <div class="form-group col-md-12">
                      <label>Num. Solicitud</label>
                      <input
                        v-model="form.id"
                        type="number"
                        class="form-control form-control-sm shadow-sm"
                      />
                    </div> -->
                    <div class="form-group col-md-12">
                      <label :class="form.empresa_id ? 'text-success ' : ''"
                        >Empresa</label
                      >
                      <v-select
                        v-model="empresa"
                        label="razon_social"
                        class="shadow-sm p-0"
                        :options="$parent.listasForms.empresas"
                        @input="selectEmpresa()"
                        :filterable="false"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label
                        :class="form.tipo_vehiculo_id ? 'text-success ' : ''"
                        >Tipo Vehículo</label
                      >
                      <v-select
                        v-model="tipo_vehiculo"
                        label="nombre"
                        class="shadow-sm p-0"
                        :options="$parent.listasForms.tipos_vehiculos"
                        @input="selectTipoVh()"
                        :filterable="false"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label :class="form.ruta_id ? 'text-success ' : ''"
                        >Ruta</label
                      >
                      <v-select
                        v-model="ruta"
                        label="nombre"
                        class="shadow-sm p-0"
                        :options="$parent.listasForms.rutas"
                        @input="selectRuta()"
                        :filterable="false"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label :class="form.producto_id ? 'text-success ' : ''"
                        >Producto</label
                      >
                      <v-select
                        v-model="producto"
                        label="nombre"
                        class="shadow-sm p-0"
                        :options="$parent.listasForms.productosLiquidos"
                        @input="selectProdLiqui()"
                        :filterable="false"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label :class="form.turno_id ? 'text-success ' : ''"
                        >Turno</label
                      >
                      <v-select
                        v-model="turno"
                        label="nombre"
                        class="shadow-sm p-0"
                        :options="$parent.listasForms.turnos"
                        :filterable="false"
                        @input="selectTurno()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label :class="form.tipo_tarifa ? 'text-success ' : ''"
                        >Tipo Tarifa</label
                      >
                      <select
                        class="form-control form-control-sm"
                        v-model="form.tipo_tarifa"
                      >
                        <option value="">seleccione...</option>
                        <option
                          v-for="tarifa in $parent.listasForms.tipos_tarifas"
                          :key="tarifa.numeracion"
                          :value="tarifa.numeracion"
                        >
                          {{ tarifa.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <label :class="form.bloque_id ? 'text-success ' : ''"
                        >Bloques</label
                      >
                      <v-select
                        class="p-0 rounded"
                        v-model="bloque"
                        placeholder="Bloque"
                        label="nombre"
                        :options="$parent.listasForms.bloques"
                        @input="[selectBloque(), getOperacionesSitio()]"
                      >
                      </v-select>
                    </div>
                    <div
                      v-if="form.bloque_id && loanding"
                      class="spinner-border text-success"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div
                      class="form-group col-md-12"
                      v-if="form.bloque_id && !loanding"
                    >
                      <label :class="form.operacion_id ? 'text-success ' : ''"
                        >Operación</label
                      >
                      <v-select
                        class="p-0 rounded"
                        v-model="operacion"
                        placeholder="Operaciones"
                        label="Noperacion"
                        :options="listasForms.operacionesSitio"
                        @input="selectOperacion()"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-gradient-navy shadow mr-2"
                v-if="!$v.form.$invalid"
                @click="exportExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
              <button
                @click="limpiarModal()"
                type="button"
                class="btn btn-sm bg-gradient-danger shadow"
              >
                Limpiar
                <i class="fas fa-file-download"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div></div
></template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SolicitudExportExcel",
  components: {
    vSelect,
  },
  data() {
    return {
      loanding: false,
      check: {
        fecha_ini: true,
        fecha_fin: true,
        empresa: true,
        turno: true,
        tipo_vh: true,
        producto: true,
        ruta: true,
        tipo_tarifa: true,
        bloque: true,
        operacion: true,
        justificacion: true,
        observaciones: true,
        estado: true,
      },
      form: {
        //id: null,
        fecha_inicial: null,
        fecha_final: null,
        empresa_id: null,
        turno_id: null,
        tipo_vehiculo_id: null,
        producto_id: null,
        ruta_id: null,
        tipo_tarifa: null,
        bloque_id: null,
        operacion_id: null,
        estado: null,
      },
      listasForms: {
        operacionesSitio: [],
      },
      empresa: [],
      tipo_vehiculo: [],
      producto: [],
      ruta: [],
      turno: [],
      bloque: [],
      operacion: [],
    };
  },

  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },

  methods: {
    limpiarModal() {
      this.check = {
        fecha_ini: true,
        fecha_fin: true,
        empresa: true,
        turno: true,
        tipo_vh: true,
        producto: true,
        ruta: true,
        tipo_tarifa: true,
        bloque: true,
        operacion: true,
        justificacion: true,
        observaciones: true,
        estado: true,
      };
      this.form = {
        //id: null,
        fecha_inicial: null,
        fecha_final: null,
        empresa_id: null,
        turno_id: null,
        tipo_vehiculo_id: null,
        producto_id: null,
        ruta_id: null,
        tipo_tarifa: null,
        estado: null,
        bloque_id: null,
        operacion_id: null,
      };
      this.empresa = [];
      this.tipo_vehiculo = [];
      this.producto = [];
      this.ruta = [];
      this.turno = [];
      this.bloque = [];
      this.operacion = [];
      this.listasForms.operacionesSitio = [];
    },

    exportExcel() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/funcionariosFrontera/tifSolicitudes/export",
        data: { form: this.form, check: this.check },
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    validarFechas() {
      if (this.form.fecha_inicial && this.form.fecha_final) {
        var fecha_menor = new Date(this.form.fecha_inicial);
        var fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        fecha_menor = new Date(this.form.fecha_inicial).getTime();
        fecha_mayor = new Date(this.form.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async getOperacionesSitio() {
      this.loanding = true;
      this.operacion = [];
      this.form.operacion_id = null;
      /* let data = this.form.bloques
        ? { bloques: this.form.bloques.map((bloque) => bloque.id) }
        : { bloques: [] };
      this.form.operaciones =
        this.form.operaciones.length > 0
          ? this.form.operaciones.filter((operacion) =>
              data.bloques.includes(operacion.bloque_id)
            )
          : []; */

      await axios({
        method: "GET",
        url: "/api/tif/sitios/operacionesSitio",
        params: this.form.bloque_id,
      }).then(async (response) => {
        this.listasForms.operacionesSitio = await response.data;
        this.listasForms.operacionesSitio = this.listasForms.operacionesSitio.filter(
          (operacion) => !operacion.Noperacion.includes("Camiones de Vacío")
        );
        this.loanding = false;
      });
    },

    selectEmpresa() {
      this.form.empresa_id = this.empresa ? this.empresa.id : "";
    },

    selectBloque() {
      this.form.bloque_id = this.bloque ? this.bloque.id : "";
    },

    selectOperacion() {
      this.form.operacion_id = this.operacion ? this.operacion.id : "";
    },

    selectTipoVh() {
      this.form.tipo_vehiculo_id = this.tipo_vehiculo
        ? this.tipo_vehiculo.id
        : "";
    },

    selectProdLiqui() {
      this.form.producto_id = this.producto ? this.producto.id : "";
    },

    selectRuta() {
      this.form.ruta_id = this.ruta ? this.ruta.id : "";
    },

    selectTurno() {
      this.form.turno_id = this.turno ? this.turno.id : "";
    },
  },
};
</script>
